import React from 'react'
import {graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import SEO from '../components/seo'
import styled from "styled-components"
import BackgroundImage from 'gatsby-background-image'
import unified from 'unified';
import markdown from 'remark-parse';
import html from 'remark-html';
import { DiscussionEmbed } from "disqus-react";
import { ShareButtonRectangle, ShareBlockStandard  } from "react-custom-share";

import {FaTwitter, FaFacebook, FaGooglePlus, FaEnvelope, FaLinkedin} from "react-icons/fa";
import Layout from '../components/layout'
// import ContactBlock from '../components/contactblock'
//import Link from "../components/translinkup"

const isBrowser = typeof window !== 'undefined';
const ScrollOut  = isBrowser? require('scroll-out') : undefined;

const StyledLayout = styled(Layout)`
`

const StyledBackgroundSection = styled(BackgroundImage)`
    background-repeat: repeat;
    background-size: 200px;
    background-attachment: fixed;
    color:#ffffff;
    position:relative;
    min-height: calc(100vh - 20.83vw);
`

const HeadLink = styled(Link)`
    position:fixed;
    background-color:#fff;
    color:#303239!important;
    padding:10px 15px 8px;
    font-size:22px;
    border-bottom-right-radius:15px;
    z-index:5; 
    transform-origin: 0 0;
    transition-duration: 400ms;
    transform: translate3d(0, 0, 0);
    transition-timing-function: cubic-bezier(.33,2.1,.73,.69);
    cursor: pointer;
    &:hover{
        transform: scale(1.2, 1.2) translate3d(0, 0, 0);
    }
`
const Contain = styled.div`
    h1{
        margin-top: 0;
        padding-top: 18px;
    }
    width:80%;
    margin: auto;
    .contact-open{
        color:#278ADB;
        transition: all 1s;
        cursor: pointer;
        &:hover{
            color:#fff;
        }
    }
    a{
        color:#278ADB;
        transition: all 1s;
        cursor: pointer;
        &:hover{
            color:#fff;
        }
    }
    .tc{
        border: 2px solid #fff;
        width: 360px;
        max-width: 50%;
        padding: 10px 15px 8px;
        margin: 0px 10px 3px 0px;
        border-radius: 10px;
        float: left;
        h2{
            margin-top: 0;
            margin-bottom: 10px;
        }
        ol{
            margin: auto;
            padding-left: 20px;
            li{
                margin-bottom: 2px;
            }
        }
    }
    .start{
        overflow: auto;
        h2{
            margin-top:0px;
        }
    }
    .start:after {
        content: "";
        clear: both;
        display: table;
    }
    @media (max-width: 600px) {
        width:90%;
    }
    @media (max-width: 720px) {
        .tc{
            width: auto;
            max-width: 100%;
            float: none;
        }
    }
`
const ContainContact = styled.div`
    text-align: center;
    margin-top: 12px;
    font-size: 20px;
`
const Footer = styled.div`
    width:100%;
    position:absolute;
    bottom: 0;
    text-align:center;
    background:#303239;
    p{
        margin: 10px auto 10px;
        font-size: 26px;
    }
    .rcs-button{
        min-height: 68px;
        svg{
            transition: .4s cubic-bezier(.33,2.1,.73,.69)
        }
    }
`
const Content = styled.div`
    margin-top: 18px;
    padding-top: 18px;
    border-top: 2px solid #fff;
    padding-bottom: 50px;
    p{
        margin-top:0px;
    }

`
let so;

export default class BlogPost extends React.Component {
    constructor(props) {
        super(props);
        this.showContact = this.showContact.bind(this);
    }
    componentDidMount(){
        so = new ScrollOut({
            targets: '#send',
            offset: 1,
            once: false,
        });
    }
    componentDidUnmount(){
        if(so){ so.teardown(); }
    }
    showContact(){
        document.getElementById("cont-contain").classList.add("show");
    }
    render() {
        const disqusShortname = "pixelfriendly";
        const disqusConfig = {
            identifier: this.props.data.airtable.data.url,
            title: this.props.data.airtable.data.url,
        };
        const shareBlockProps = {
            url: "https://pixelfriendly.co.za/blog/"+this.props.data.airtable.data.url,
            button: ShareButtonRectangle,
            buttons: [
                { network: "Twitter", icon: FaTwitter },
                { network: "Facebook", icon: FaFacebook },
                { network: "GooglePlus", icon: FaGooglePlus },
                { network: "Email", icon: FaEnvelope },
                { network: "Linkedin", icon: FaLinkedin }
                ],
            text: `Give this a read - `+this.props.data.airtable.data.Name,
            longtext: `Take a look at this super website I have just found.`
        }; 
        return(  
            
        <StyledLayout id="blog-post" className="section-wrapper">
            <SEO title={this.props.data.airtable.data.Name} description={this.props.data.airtable.data.description} keywords={[this.props.data.airtable.data.Keywords]}/>
            <HeadLink to="/">Home</HeadLink>
            <Img fluid={this.props.data.airtable.data.BlogImage.localFiles[0].childImageSharp.fluid}/>
            <StyledBackgroundSection fluid={this.props.data.background.childImageSharp.fluid} backgroundColor={`#000000`}>
                <Contain>
                    <h1>{this.props.data.airtable.data.Name}</h1>
                    <span>Posted on: {this.props.data.airtable.data.Date}</span>
                    <ContainContact>
                        If you are interested in our Web Design or Graphic Design services <span className="contact-open" onClick={this.showContact}>Contact us.</span>
                    </ContainContact>
                    <Content dangerouslySetInnerHTML={{
                        __html: unified()
                        .use(markdown)
                        .use(html)
                        .processSync(this.props.data.airtable.data.Content)
                    }}
                    />
                    <DiscussionEmbed shortname={disqusShortname} config={disqusConfig} />
                </Contain>
                <div className="sharethis-inline-share-buttons"></div>
                <Footer>
                    <div><p>Share this post</p></div>
                    <ShareBlockStandard  {...shareBlockProps} />
                </Footer>
            </StyledBackgroundSection>
            {/* <ContactBlock/> */}
        </StyledLayout>
        )
    }
} 

export const query = graphql`
query PostQuery($url: String!) {
    background:file(relativePath: { eq: "type.png" }) {
        childImageSharp {
            fluid(quality: 100, maxWidth: 200, maxHeight: 200) {
            ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    airtable(table: {eq: "Blog"}, data: {url: {eq: $url}}){
        data{
            Name
            Keywords
            Content
            url
            Date (formatString: "DD MMMM YYYY")
            BlogImage{
                localFiles{
                    childImageSharp{
                        fluid(maxWidth: 1920 maxHeight: 400 cropFocus: CENTER,  quality: 90) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        }
    }
}
`